var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-forms-view","fluid":"","tag":"section"}},[_c('v-row',[(!_vm.isNew && !_vm.section)?_c('v-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"fas fa-spin fa-spinner"})]):_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{attrs:{"color":"primary","heading":_vm.pageTitle}},[_c('v-card-text',[(!_vm.isNew && !_vm.section)?_c('i',{staticClass:"fas fa-spin fa-spinner"}):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.label').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.label')},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}})]}}],null,true)}),(_vm.url)?_c('div',[_vm._v(" Adresse d'accès : "+_vm._s(_vm.url)+" "),_c('span',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.url),expression:"url",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.urlCopiedHandler),expression:"urlCopiedHandler",arg:"success"}],staticClass:"ml-2",attrs:{"role":"button"}},[_c('i',{staticClass:"fas fa-link"})])]):_vm._e(),_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.in_burger_menu').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":_vm.$t('forms.in_burger_menu'),"error-messages":errors},model:{value:(_vm.form.is_in_burger_menu),callback:function ($$v) {_vm.$set(_vm.form, "is_in_burger_menu", $$v)},expression:"form.is_in_burger_menu"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.on_homepage').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":_vm.$t('forms.on_homepage'),"error-messages":errors},model:{value:(_vm.form.is_on_homepage),callback:function ($$v) {_vm.$set(_vm.form, "is_on_homepage", $$v)},expression:"form.is_on_homepage"}})]}}],null,true)}),(_vm.form.is_on_homepage)?_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.homepage_icon').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropzoneComponent',{attrs:{"aria-errormessage":errors,"label":_vm.$t('dropzone.label_singular'),"max-files":1,"field-label":_vm.$t('forms.homepage_icon'),"no-crop":""},model:{value:(_vm.form.homepage_icons),callback:function ($$v) {_vm.$set(_vm.form, "homepage_icons", $$v)},expression:"form.homepage_icons"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.color').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ColorPicker',{staticClass:"mt-3",attrs:{"error-messages":errors,"label":_vm.$t('forms.color')},model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('forms.parent_section')))]),_c('SectionTreeView',{key:("tree-" + (_vm.form.section_id)),attrs:{"forbidden":_vm.form.id},model:{value:(_vm.form.section_id),callback:function ($$v) {_vm.$set(_vm.form, "section_id", $$v)},expression:"form.section_id"}})],1)],1),_c('div',{staticClass:"full-width d-flex justify-space-between align-center"},[_c('v-btn',{attrs:{"color":"gray","to":"/pages/sections"}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.initiateSectionDelete}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":_vm.processing ? 'gray' : 'success'}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.processing ? 'fas fa-spinner fa-spin' : 'fas fa-check')}}),_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")],1)],1)],1)]}}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.showDeletionDialog),callback:function ($$v) {_vm.showDeletionDialog=$$v},expression:"showDeletionDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" "+_vm._s(_vm.$t('delete_confirm'))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":_vm.closeDeletionDialog}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteSection}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }