<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-if="!isNew && !section"
        cols="12"
      >
        <i class="fas fa-spin fa-spinner"></i>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <material-card
          color="primary"
          :heading="pageTitle"
        >
          <v-card-text>
            <i
              v-if="!isNew && !section"
              class="fas fa-spin fa-spinner"
            ></i>
            <validation-observer v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(submit)">
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.label').toLowerCase()"
                    >
                      <v-text-field
                        v-model="form.label"
                        :error-messages="errors"
                        outlined
                        :label="$t('forms.label')"
                      />
                    </validation-provider>
                    <div v-if="url">
                      Adresse d'accès : {{ url }}
                      <span
                        v-clipboard:copy="url"
                        v-clipboard:success="urlCopiedHandler"
                        class="ml-2"
                        role="button"
                      >
                        <i class="fas fa-link"></i>
                      </span>
                    </div>
                    <validation-provider
                      v-slot="{ errors }"
                      rules=""
                      :name="$t('forms.in_burger_menu').toLowerCase()"
                    >
                      <v-switch
                        v-model="form.is_in_burger_menu"
                        :label="$t('forms.in_burger_menu')"
                        :error-messages="errors"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      rules=""
                      :name="$t('forms.on_homepage').toLowerCase()"
                    >
                      <v-switch
                        v-model="form.is_on_homepage"
                        :label="$t('forms.on_homepage')"
                        :error-messages="errors"
                      />
                    </validation-provider>
                    <validation-provider
                      v-if="form.is_on_homepage"
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.homepage_icon').toLowerCase()"
                    >
                      <DropzoneComponent
                        v-model="form.homepage_icons"
                        :aria-errormessage="errors"
                        :label="$t('dropzone.label_singular')"
                        :max-files="1"
                        :field-label="$t('forms.homepage_icon')"
                        no-crop
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('forms.color').toLowerCase()"
                    >
                      <ColorPicker
                        v-model="form.color"
                        :error-messages="errors"
                        :label="$t('forms.color')"
                        class="mt-3"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <h4>{{ $t('forms.parent_section') }}</h4>
                    <SectionTreeView
                      :key="`tree-${form.section_id}`"
                      v-model="form.section_id"
                      :forbidden="form.id"
                    />
                  </v-col>
                </v-row>
                <div class="full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/pages/sections"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    color="error"
                    @click="initiateSectionDelete"
                  >
                    {{ $t('delete') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDeletionDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deleteSection"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DropzoneComponent from '../../components/Dropzone';
  import SectionTreeView from '../../components/SectionTreeView';
  import serverError from '../../mixins/serverError';
  export default {
    name: 'SectionForm',

    components: { DropzoneComponent, SectionTreeView },

    mixins: [serverError],

    data: () => ({
      form: {
        label: null,
        color: '#FFFFFF',
        is_in_burger_menu: false,
        is_on_homepage: false,
        homepage_icon_id: null,
        section_id: null,
        homepage_icons: [],
      },
      sections: [],
      showDeletionDialog: false,
    }),

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
        section: 'page/getSection',
        allSections: 'page/getSections',
      }),
      isNew() {
        return !this.$route.params.id;
      },
      pageTitle() {
        return this.isNew ? this.$t('pageTitles.NewSection') : this.$t('pageTitles.Editing') + this.section.label;
      },
      url() {
        if (this.isNew) {
          return null;
        }
        return `https://www.mairie.mc/r/${this.section.slug}`;
      },
    },

    created() {
      this.$store.dispatch('page/fetchSections').then(() => {
        this.sections = this.$route.params.id
          ? [...this.allSections.filter(section => section.id !== parseInt(this.$route.params.id))]
          : [...this.allSections];
      });
      if (!this.isNew) {
        this.$store.dispatch('page/fetchSection', this.$route.params.id)
          .then(() => {
            this.form = {
              ...this.section,
              homepage_icons: this.section.homepage_icon ? [this.section.homepage_icon] : [],
            };
          });
      }
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { id, label, color, is_in_burger_menu, is_on_homepage, homepage_icons, section_id } = this.form
          const homepage_icon_id = !homepage_icons || !homepage_icons[0] || !homepage_icons[0].id ? null : homepage_icons[0].id;
          const payload = { id, label, color, is_in_burger_menu, is_on_homepage, homepage_icon_id, section_id };
          const action = this.isNew ? 'page/createSection' : 'page/updateSection';
          const message = this.isNew ? this.$t('successfully_created') : this.$t('successfully_updated');
          this.$store.dispatch(action, payload)
            .then(() => {
              this.$toasted.success(message);
              this.$router.push('/pages/sections');
            })
            .catch(error => this.displayErrors(error));
        }
      },
      initiateSectionDelete() {
        this.showDeletionDialog = true;
      },
      closeDeletionDialog() {
        this.showDeletionDialog = false;
      },
      deleteSection() {
        this.$store.dispatch('page/deleteSection', this.$route.params.id)
          .then(() => {
            this.$toasted.success(this.$t('item_deleted'));
            this.$router.push('/pages/sections');
          })
          .catch(error => this.displayErrors(error))
          .finally(() => this.closeDeletionDialog());
      },
      urlCopiedHandler() {
        this.$toasted.success(this.$t('url_copied'));
      },
    },
  }
</script>
